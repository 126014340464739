import styled from '@emotion/styled'
import { Link } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { breakpoints } from '../utils/styles'
import ProductGrid from './../components/ProductGrid'
import NewTopShop from './../components/Shop/TopShop/NewTopShop'
import SEO from './../components/seo'

const EShop = ({ location }) => {
  const [collection, setCollection] = useState('Nos cafés')

  useEffect(() => {
    if (location.state) {
      // console.log(location.state.collection)

      if (location.state.collection === undefined) {
        setCollection('Nos cafés')
      } else {
        setCollection(location.state.collection)
      }
    }
  }, [location])
  return (
    <div>
      <SEO title="E-shop" description="Page E-shop" />
      <NewTopShop title='E-shop' />
      <ButtonBox>
        <Button
          id='toggle1'
          selected={collection === 'Nos cafés'}
          to='/e-shop#toggle1'
          state={{ collection: 'Nos cafés' }}
          replace
        >
          Nos cafés
        </Button>
        <Separator />
        <Button
          id='toggle2'
          selected={collection === 'S\'équiper Jh'}
          to='/e-shop#toggle2'
          state={{ collection: 'S\'équiper Jh' }}
          replace
        >
          S'équiper JH
        </Button>
      </ButtonBox>
      <ProductGrid collection={collection} title='E-shop' isHomePage={false} />
    </div>
  )
}

export default EShop

export const ButtonBox = styled.div`
  width: 26em;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3.5em;

  @media (max-width: ${breakpoints.s}px) {
    flex-direction: column;
    align-content: space-between;
    height: 7em;
    width: 100%;
  }
`

export const Button = styled(Link)`
  width: 10em;
  height: 2em;
  border-radius: 30px;
  border: 2px solid #2b5c6b;
  background-color: ${props => props.selected ? '#2b5c6b' : 'transparent'};
  color: ${props => props.selected ? '#eed7b8' : '#2b5c6b'};
  font-size: 1.2em;
  font-family: "Krona One";
  font-weight: 400;
  text-decoration: none;
  text-align: center;
  line-height: 2;

  @media (max-width: ${breakpoints.l}px) {
    
  }
  
  :hover {
    cursor: pointer;
  }
`

export const Separator = styled.div`
  height: 3.5em;
  width: 2px;
  border-left: 2px solid #2b5c6b;

  @media (max-width: ${breakpoints.s}px) {
    border-left: 0;
    border-bottom: 2px solid #2b5c6b;
    width: 5em;
    height: 2px;
  }
`
