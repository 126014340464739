import { graphql, useStaticQuery } from 'gatsby'
import React, { useContext, useEffect, useState } from 'react'
import StoreContext from '~/context/StoreContext'
import { getPrice } from '../../utils/function'
import {
  AvaibleButton,
  Container, Grid,
  NotAvaibleButton,
  PriceTag,
  Product,
  ProductImg,
  ProductImgLink,
  ProductsTitle, RegularPriceTag, SubTitle,
  Title
} from './styles'

const ProductGrid = ({ isHomePage, collection }) => {
  const [isAvaible, setIsAvaible] = useState([])
  const [shopifyProducts, setShopifyProducts] = useState([])

  const {
    store: { client }
  } = useContext(StoreContext)

  const checkAvaiblelity = i => {
    if (shopifyProducts.length !== 0) {
      return client.product.fetch(i).then(res => {
        // console.log(res.avaibleForSale)
        return res.availableForSale
      })
    }
  }

  const { allShopifyCollection } = useStaticQuery(
    graphql`
        query allShopifyCollection {
          allShopifyCollection {
              edges {
                  node {
                      title
                      products {
                          id
                          title
                          handle
                          productType
                          shopifyId
                          createdAt
                          availableForSale
                          images {
                              id
                              originalSrc
                              localFile {
                                  childImageSharp {
                                      fluid(maxWidth: 300) {
                                          ...GatsbyImageSharpFluid_withWebp_tracedSVG
                                      }
                                  }
                              }
                          }
                          priceRange {
                              maxVariantPrice {
                                  amount
                              }
                              minVariantPrice {
                                  amount
                              }
                          }
                          variants {
                              price
                              presentmentPrices {
                                  edges {
                                      node {
                                          compareAtPrice {
                                              amount
                                          }
                                          price {
                                              amount
                                          }
                                      }
                                  }
                              }
                          }
                      }
                  }
              }
          }
      }
    `
  )

  // console.log(shopifyProducts)

  useEffect(() => {
    if (collection) {
      const matchCollection = allShopifyCollection.edges.find(({ node: { title } }) => title === collection)
      setShopifyProducts(matchCollection.node.products)
      // console.log(matchCollection)
    }
  }, [collection])

  useEffect(() => {
    shopifyProducts.forEach(async product => {
      // console.log(product)
      const response = await checkAvaiblelity(product.shopifyId)
      setIsAvaible(state => [
        ...state,
        {
          id: product.shopifyId,
          isAvaible: response
        }
      ])
    })
  }, [shopifyProducts])

  return (
    <Container>
      {isHomePage &&
        <ProductsTitle>
          Les produits de saison
        </ProductsTitle>}
      <Grid isHomePage={isHomePage}>
        {shopifyProducts.length !== 0
          ? (
              shopifyProducts
                .filter(({ productType, title }) => isHomePage ? productType === 'saison' : title)
                .slice(0, isHomePage ? 4 : shopifyProducts.length)
                .sort((a, b) => b.availableForSale - a.availableForSale)
                .map(
                  ({
                    id,
                    handle,
                    title,
                    shopifyId,
                    priceRange,
                    variants,
                    images: [firstImage],
                    variants: [firstVariant]
                  }) => {
                    const titles = title.split(' - ')
                    const avaible = isAvaible.find(e => e.id === shopifyId)
                    // console.log(variants)
                    return (
                      <Product key={id}>
                        <ProductImgLink to={`/e-shop/${handle}#product`}>
                          {firstImage && firstImage.localFile && (
                            <ProductImg
                              fluid={firstImage.localFile.childImageSharp.fluid}
                              alt={handle}
                            />
                          )}
                        </ProductImgLink>
                        <Title>{titles[0]}</Title>
                        <SubTitle>{titles[1]}</SubTitle>
                        {variants.length !== 1
                          ? (
                            <RegularPriceTag>
                              {
                                getPrice(priceRange.minVariantPrice.amount) === getPrice(priceRange.maxVariantPrice.amount)
                                  ? getPrice(priceRange.minVariantPrice.amount)
                                  : `${getPrice(priceRange.minVariantPrice.amount)} - ${getPrice(priceRange.maxVariantPrice.amount)}`
                              }
                            </RegularPriceTag>
                            ) : (
                              <PriceTag>{getPrice(firstVariant.price)}</PriceTag>
                            )}
                        {avaible && avaible.isAvaible ? (
                          <AvaibleButton>Disponible</AvaibleButton>
                        ) : (
                          <NotAvaibleButton>
                            Hors saison | indisponible
                          </NotAvaibleButton>
                        )}
                      </Product>
                    )
                  })
            )
          : (
            <p style={{ margin: '3em auto 0 auto' }}>Produits JH bientôt disponible</p>
            )}
      </Grid>
    </Container>
  )
}

export default ProductGrid
